<template>
  <div class="codenrock-logo-container">
    <img :src="LogoByCodenrock" alt=""/>
  </div>
</template>

<script>
export default {
  name: "LogoByCodenrock",
  data() {
    return {
      LogoByCodenrock: require("@/assets/logo-by-codenrock.svg"),
    }
  }
}
</script>

<style scoped>
.codenrock-logo-container {
  margin-top: 36px;
  display: flex;
  justify-content: center;
  height: 40.997px;
}
</style>