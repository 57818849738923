<template>
  <div class="logo">
    <router-link to="/">
      <img alt="logo" :src="img"/>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "logo",
  computed: {
    img() {
      if(this.$route.path.indexOf('/game/tasks') !== -1){
        return require('../assets/logo.png');
      }
      return require('../assets/logo.png');
    }
  }
}
</script>

<style scoped lang="scss">
.logo {
  //margin-top: 98px;
  text-align: center;
  /*margin-top: 2rem;*/
  img {
    height: 51px;
  }

  @media (min-width: 992px) {
    //text-align: left;
    height: 51px;
    //margin-left: 120px;
  }
}
</style>