import {createStore} from 'vuex'
import CompetitionClient from "cnr-client";

export default createStore({
    state: {
        client: undefined as CompetitionClient | undefined,
        user: undefined,
        contest: undefined,
        nominations: [],
    },
    getters: {
        client: state => state.client,
        user: state => state.user,
        contest: state => state.contest,
        nominations: state => state.nominations,
    },
    mutations: {
        setClient(state, client) {
            state.client = client;
        },
        setUser(state, user) {
            state.user = user;
        },
        setContest(state, contest) {
            state.contest = contest;
        },
        setNominations(state, nominations) {
            state.nominations = nominations;
        }
    },
    actions: {
        initClient({commit}) {
            const client = new CompetitionClient({
                contestId: process.env.VUE_APP_CONTEST_ID,
                apiEndpoint: process.env.VUE_APP_ENDPOINT_URL,
                ssoUrl: ''
            });
            let params = (new URL(document.location.toString())).searchParams;
            let token = params.get('token');
            if (token) {
                client.setToken(token);
                window.location.href = `${window.location.origin}/game/rules`
            }
            commit('setClient', client);
        },
        async getUser({commit, state}) {
            if (state.client) {
                const user = await state.client.getProfile();
                commit('setUser', user)
            }
        },
        async getContest({commit, state}) {
            if (state.client) {
                const contest = await state.client.getContest();
                commit('setContest', contest);
            }
        },
        async getNominations({commit, state}) {
            if (state.client) {
                const nominations = await state.client.getNominations();
                commit('setNominations', nominations)
            }
        }
    },
    modules: {}
})
