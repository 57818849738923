import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import HomeLayout from '../layouts/HomeLayout.vue'
import store from "@/store";
import MainView from "@/views/MainView.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        component: HomeLayout,
        children: [
            {
                path: '/',
                name: 'main',
                component: MainView,
            },
            {
                path: '/sign-up',
                name: 'sign-up',
                component: () => import('../views/SignUpView.vue'),
            },
            {
                path: '/sign-in',
                name: 'sign-in',
                component: () => import('../views/SignInView.vue'),
            },
            {
                path: '/confirm',
                name: 'confirm',
                component: () => import('../views/ConfirmEmailView.vue'),
                meta: {
                    auth: true,
                }
            },
            {
                path: '/form',
                name: 'form',
                component: () => import('../views/FormView.vue'),
                meta: {
                    auth: true,
                }
            },

            {
                path: '/rating',
                name: 'rating',
                component: () => import('../views/RatingView.vue'),
            },
            {
                path: 'tasks/:nominationId/finish',
                name: 'finish',
                component: () => import('../views/FinishView.vue'),
            },
        ]
    },
    {
        path: '/game',
        name: 'game',
        component: () => import('../layouts/GameLayout.vue'),
        meta: {
            auth: true,
        },
        children: [
            {
                path: 'rules',
                name: 'rules',
                component: () => import('../views/RulesView.vue'),
            },
            {
                path: 'tasks/:nominationId',
                name: 'tasks',
                component: () => import('../views/TasksView.vue'),
            },


        ]
    },
    {path: "/404", component: () => import('../views/PageNotFound.vue')}
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})


router.beforeEach((to, from, next) => {
        if (to === from) {
            return;
        }
        if (!to.matched.length) {
            next('/404');
            return;
        } else {
            const guardRoute = to.matched.some(record => record.meta.auth);
            const game = store.getters.client;
            const auth = game && game.authorized;
            if (guardRoute && !auth) {
                next({path: "/sign-up"})
            } else {
                next()
            }
        }
    }
)

export default router
