import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import "bootstrap-icons/font/bootstrap-icons.css";
import VuePapaParse from 'vue-papa-parse';

const app = createApp(App);

app.use(store);
app.use(router);
app.use(VuePapaParse);

app.mount('#app');

// createApp(App).use(VuePapaParse)
